<template>
    <seccion-datos :titulo="estadopendientedatos" @cambios="guardar()">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ $t('general.nombre') }}</label>
                    <textarea class="form-control nombre_completo dato" v-model="texto_presupuesto" rows="8"></textarea>
                </div>
            </div>            
        </div>        
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: {
        id: Number,
        
    },
    data() {
        return {
            texto_presupuesto: '',
            estadopendientedatos: "Datos", 
            condicion_presupuesto: Object
        }
    },
    methods: {
        async cargardatos() {
           
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const datos = await api.get('condiciones/' + this.id);
            this.condicion_presupuesto = datos.datos;
            this.$store.dispatch('cambiarCargando', false);
            this.texto_presupuesto = this.condicion_presupuesto.texto;
            
          
        },
        async guardar() {
            const api = new PwgsApi;
            const datos = { texto : this.texto_presupuesto };
            if (this.id > 0) {
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('condiciones/' + this.id, datos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$parent.obtenerCondicionPresupuesto(this.id);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            else {
                let res;
                res = await api.post('condiciones/', datos);
                this.$router.push('/condiciones/' + res.id);
            }
        },
        comprobar() { }
    },
    mounted() {
        this.cargardatos();
    }
}
</script>