<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>Condición Presupuesto</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>Crear Condiciones Presupuestos</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_estado_pendiente" data-toggle="tab">{{
                                    $t('general.datos') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_estado_pendiente">
                            <condiciones-presupuesto-datos :id="id" :key="id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import CondicionPresupuestoDatos from './CondicionesPresupuestoDatos.vue'
export default {
    components: {
        'condiciones-presupuesto-datos': CondicionPresupuestoDatos,
    },
    props: ['id'],
    data() {
        return {
            condicion_presupuesto: [],
        }
    },
    methods: {
        async obtenerCondicionPresupuesto(id) {
            /* get  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = await api.get('condiciones/' + id); 
            this.condicion_presupuesto = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/condiciones/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.condicion_presupuesto.texto.slice(0,20)+"...";
                    this.$store.getters.ventanas[i].alt = "Condición Presupuesto: " + this.condicion_presupuesto.texto.slice(0,100) + "...";
                }
            }
            /**/
        },

        cambios() {
            this.obtenerCondicionPresupuesto(this.id);
        },
        recargar() {
            this.obtenerCondicionPresupuesto(this.id);
        },
    },
    mounted() {
        this.obtenerCondicionPresupuesto(this.id);
    },
    watch: {
        id() {
            this.obtenerCondicionPresupuesto(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/condiciones/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.condicion_presupuesto.texto.slice(0, 20) + "...";
                    this.$store.getters.ventanas[i].alt = "Condición Presupuesto: " + this.condicion_presupuesto.texto.slice(0, 100) + "...";
                }
            }
            /**/
        }
    }
}
</script>
